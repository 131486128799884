<template>
  <div style="max-width: 800px; margin: auto">
    <v-card  class="overflow-x-hidden" flat>
      <v-card-title :style="`color: ${$vuetify.theme.themes.light.primary}`">
        Please select the best time and phone number to reach you.
      </v-card-title>
      <v-card-actions>
        <template>
          <v-row
            justify="space-around"
            align="center"
          >
            <div class="v-picker v-card v-picker--time theme--light" @click="timer = true">
              <div class="v-picker__title primary">
                <div class="v-time-picker-title">
                  <div class="v-time-picker-title__time">
                    <div class="v-picker__title__btn v-picker__title__btn--active">{{ hour }}</div>
                    <span>:</span>
                    <div class="v-picker__title__btn">{{ minute }}</div>
                  </div>
                </div>
              </div>
            </div>
          </v-row>
        </template>
      </v-card-actions>
      <v-card-title class="p-b-0">
        <v-text-field
          label="Primary Phone"
          v-mask="'(###) ###-####'"
          v-model="cellPhone"
        ></v-text-field>
      </v-card-title>
      <v-card-title class="p-b-0 p-t-0">
        <v-text-field
          label="Secondary Phone"
          v-mask="'(###) ###-####'"
          v-model="homePhone"
        ></v-text-field>
      </v-card-title>
      <v-card-actions class="m-t-15">
        <v-btn x-large block color="primary" class="width-150" @click="close" style="margin-left: 8px">Close</v-btn>
        <v-btn x-large block color="primary" class="width-150" @click="submit">submit</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="timer">
      <v-time-picker
        v-model="picker"
        full-width
        format="24hr"
        min="9:30"
        max="22:15"
      ></v-time-picker>
    </v-dialog>
  </div>
</template>
<script>
import { DataType } from '../../../js/core'

export default {
  name: 'follow-up',
  props: {
    data: Object
  },
  data () {
    return {
      picker: null,
      timer: false,
      cellPhone: '',
      homePhone: '',
      personal: {},
      loanId: ''
    }
  },
  created () {
    this.personal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))
    this.cellPhone = this.personal.cellPhone
    this.homePhone = this.personal.homePhone
    this.loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
  },
  computed: {
    hour () {
      if (this.picker) {
        return this.picker.split(':')[0]
      } else {
        return '--'
      }
    },
    minute () {
      if (this.picker) {
        return this.picker.split(':')[1]
      } else {
        return '--'
      }
    }
  },
  methods: {
    submit () {
      this.$emit('submit', { loanId: this.loanId, followUpAt: this.picker, cellPhone: this.cellPhone, homePhone: this.homePhone })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
